<template>
  <v-layout wrap>
    <v-flex xs12 pl-1 v-for="(link, i) in video" :key="i">
      <span>Video Links</span></v-flex
    >
    <v-flex xs3>
      <v-text-field
        class="homeText"
        outlined
        dense
        type="text"
        v-model="link"
        label=" Youtubelinks"
      ></v-text-field>
    </v-flex>
    <v-flex xs3 pl-1><v-btn>save</v-btn></v-flex>
  </v-layout>
</template>



<script>
import axios from "axios";
export default {
  data() {
    return {
      video: [],
      link: null,

      id: this.$route.query.id,
    };
  },

  mounted() {
    this.getlink();
  },

  methods: {
    getlink() {
       
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/media/admin/add/videoURL",
        data: {
          id: this.id,
        },
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.video = response.data.data;
          this.this.appLoading = false;
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>







